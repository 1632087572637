
import ProductGridCard from '@/components/Products/ProductGridCard.vue';
import ProductListCard from '@/components/Products/ProductListCard.vue';
import { dispatchSearchProductsList } from '@/store/products/actions';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: {
        ProductGridCard,
        ProductListCard,
    },
})

export default class SearchResultsList extends Vue {
  public perPage = 10;
  public page = 1;
  public productListType: string = 'grid';
  public productSortType: string = '-rating';
  public loadingState: any = {
    list: false,
    page: true,
  };
  public productsResponse: any = {
    count: 0, next: 0, previous: 0, total: 0, total_count: 0, results: [], breadcrumbs: [],
  };

  @Watch('$route.query')
  public async changeQuery(new_val, prev_val) {
    this.handleChangePage(1, true);
  }

  public mounted() {
    this.loadingState.list = true;
    this.handleChangePage(1, true);
    this.loadingState.list = false;
    this.loadingState.page = false;
  }

  public async handleChangePage(page, force = false) {
    if (this.page != page || force) {
      this.loadingState.list = true;
      this.page = page;
      const rowQuery = `?page=${this.page}&per_page=${this.perPage}`;

      this.productsResponse = await dispatchSearchProductsList(
        this.$store, {data: {
          search: this.$router.currentRoute.query.search.toString(),
          order_by: this.productSortType,
        }, rowQuery},
      );
      this.loadingState.list = false;
    }
  }
  
  public hadnleChangeOrdering(ordering) {
    if (this.productSortType !== ordering) {
      this.productSortType = ordering;
      this.handleChangePage(1, true);
    }
  }

  get sortTitle() {
    if (this.productSortType === '-rating') {
      return this.$t('По рейтингу');
    } else if (this.productSortType === '-created_at') {
      return this.$t('По дате');
    } else if (this.productSortType === 'price') {
      return this.$t('От дешевых к дорогим');
    } else if (this.productSortType === '-price') {
      return this.$t('От дорогих к дешевым');
    }
    return '';
  }

}
